import React, { useState, useEffect } from 'react';

import Button from './Button';
import ImageDisplay from './ImageDisplay';
import Register from './Register';
import Stats from './Stats';
import API from '../api';
import '../styles/lastday.css';
import confettiGif from '../images/confetti3.gif';


const EVENT_START_DATE = new Date('2024-11-21T00:00:00');
const CLICKS_PER_DAY = 30;

const Poga = ({ isMuted }) => {
    const [totalClicks, setTotalClicks] = useState(0);
    const [userClicks, setUserClicks] = useState(0);
    const [allTimeClicks, setAllTimeClicks] = useState(0);
    const [username, setUsername] = useState(null);
    const [clickLimitReached, setClickLimitReached] = useState(false);
    const [unlockedDays, setUnlockedDays] = useState([]);
    const [showStats, setShowStats] = useState(true);
    const [clickLimitOverride, setClickLimitOverride] = useState(false);
    const [countdown, setCountdown] = useState(null);
    const [isGameButtonDisabled, setIsGameButtonDisabled] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [pauseMessage, setPauseMessage] = useState('')
    const [isGameOver, setIsGameOver] = useState(false);

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedIsGameButtonDisabled = JSON.parse(localStorage.getItem('isGameButtonDisabled'));
        const storedIsGameOver = JSON.parse(localStorage.getItem('isGameOver'));
       
    
        if (storedUsername) {
            setUsername(storedUsername);
            fetchData(storedUsername);
        }
    
        if (storedIsGameOver) {
            setIsGameOver(storedIsGameOver);
        }
    
        if (isGameOver) {
            console.log('Game Over detected, showing confetti');
            setShowConfetti(true);
        }

        if (storedIsGameButtonDisabled) setIsGameButtonDisabled(storedIsGameButtonDisabled);
        
    
       
       

        fetchClickLimitOverride();
        const overrideInterval = setInterval(fetchClickLimitOverride, 8000);
    
        const checkCountdownTrigger = () => {
            if (localStorage.getItem('startCountdown') === 'true') {
                startCountdown();
                localStorage.removeItem('startCountdown');
            }
        };
        checkCountdownTrigger();
        const countdownTriggerInterval = setInterval(checkCountdownTrigger, 1000);
    
        return () => {
            clearInterval(overrideInterval);
            clearInterval(countdownTriggerInterval);
        };
    }, [isGameOver]);

    useEffect(() => {
        const fetchCountdown = async () => {
            try {
                const response = await API.get('/countdown');
                const { active, timeLeft, isGameOver: serverGameOver } = response.data;

                setCountdown(active ? timeLeft : null);
                setIsGameOver(serverGameOver);

                if (serverGameOver || timeLeft === 0) {
                    unlockAllDays();
                }
            } catch (error) {
                console.error('Error fetching countdown status:', error);
            }
        };

        fetchCountdown();
        const interval = setInterval(fetchCountdown, 1000);

        return () => clearInterval(interval);
    }, []);

    const unlockAllDays = () => {
        const totalDays = 10;
        const allDays = Array.from({ length: totalDays }, (_, i) => i + 1);
        setUnlockedDays(allDays);
    };

    useEffect(() => {
        if (isGameOver) {
            const fetchTotalClicksForGameOver = async () => {
                try {
                   
                    const response = await API.get('/clicks');
                    const updatedTotalClicks = response.data.totalClicks || 0;
    
                    setTotalClicks(updatedTotalClicks);
    
                    
                    const totalDays = Math.ceil(updatedTotalClicks / CLICKS_PER_DAY);
                    const allDays = Array.from({ length: totalDays }, (_, i) => i + 1);
                    setUnlockedDays(allDays);
                } catch (error) {
                    console.error('Error fetching total clicks during game over:', error);
                }
            };
    
            fetchTotalClicksForGameOver();
        }
    }, [isGameOver]);
    

    const fetchClickLimitOverride = async () => {
        try {
            const response = await API.get('/admin/get-click-limit-override');
            const isUnlimitedClickMode = response.data.clickLimitOverride;
    
            setClickLimitOverride(isUnlimitedClickMode);
    
            
            if (isUnlimitedClickMode) {
                setPauseMessage('');
                setIsButtonDisabled(false);
            }
        } catch (error) {
            console.error('Error fetching click limit override status:', error);
        }
    };

    const getDaysPassed = () => {
        const today = new Date();
        return Math.floor((today - EVENT_START_DATE) / (1000 * 60 * 60 * 24)) + 1;
    };

    const fetchData = (usernameToFetch) => {
        const user = usernameToFetch || username;
        if (!user) return;

        fetchTotalClicks();
        fetchUserDailyClicks(user);
        fetchUserAllTimeClicks(user);
    };

    const fetchTotalClicks = () => {
        API.get('/clicks')
            .then(response => setTotalClicks(response.data.totalClicks || 0))
            .catch(error => console.error('Error getting total clicks:', error));
    };

    const fetchUserDailyClicks = (user) => {
        API.get(`/user-clicks?username=${user}`)
            .then(response => {
                const data = response.data;
                const daysPassed = getDaysPassed();
                const maxAvailableClicks = daysPassed * CLICKS_PER_DAY;
                const todayClicks = Math.min(data.clickCount || 0, maxAvailableClicks);

                setUserClicks(todayClicks);

                const limitReached = todayClicks >= maxAvailableClicks;
                setClickLimitReached(limitReached);

                if (limitReached) {
                    localStorage.setItem('clickLimitReached', 'true');
                    localStorage.setItem('clickLimitDate', new Date().toDateString());
                } else {
                    localStorage.removeItem('clickLimitReached');
                    localStorage.removeItem('clickLimitDate');
                }
            })
            .catch(error => console.error("Error fetching user's daily clicks:", error));
    };

    const fetchUserAllTimeClicks = (user) => {
        API.get(`/user-alltime-clicks?username=${user}`)
            .then(response => {
                const data = response.data;
                setAllTimeClicks(data.allTimeClicks || 0);
                const unlockedDaysArray = Array.from(
                    { length: Math.floor((data.allTimeClicks || 0) / 30) },
                    (_, i) => i + 1
                );
                setUnlockedDays(unlockedDaysArray);
            })
            .catch(error => console.error('Error fetching all-time clicks:', error));
    };

    const handleClick = () => {
        const daysPassed = getDaysPassed();
        const maxAvailableClicks = daysPassed * CLICKS_PER_DAY;


        if (isGameOver) return;
        
        if (!username) {
            console.error('Error: Username is not defined');
            return;
        }

        if (isButtonDisabled) return;

        if (clickLimitOverride || userClicks < maxAvailableClicks) {
            Promise.all([
                API.post('/clicks', { clicks: 1 }).then(response => response.data),
                API.post('/user-clicks', { username }).then(response => {
                    if (response.status === 200) return response.data;
                    else throw new Error('Limit reached');
                }),
            ])
                .then(([totalClicksData, userClicksData]) => {
                    setTotalClicks(totalClicksData.totalClicks || totalClicks);
                    setUserClicks(userClicksData.clickCount || userClicks);

                    setClickLimitReached(!clickLimitOverride && userClicksData.clickCount >= maxAvailableClicks);
                    fetchData();

                   
                    if (!clickLimitOverride && (userClicksData.clickCount || 0) % 30 === 0) {
                        setPauseMessage('Jauns elements atvērts!');
                        setIsButtonDisabled(true);
    
                        setTimeout(() => {
                            setPauseMessage('');
                            setIsButtonDisabled(false);
                        }, 5000);
                    }
                })
                .catch(error => {
                    console.error('Error handling clicks:', error);
                    setClickLimitReached(true);
                });
        } else {
            setClickLimitReached(true);
        }
    };


    const resumeCountdown = (remainingCountdown) => {
        setCountdown(remainingCountdown);
        const endTime = Date.now() + remainingCountdown * 1000;
    
        const countdownInterval = setInterval(() => {
            const timeLeft = Math.ceil((endTime - Date.now()) / 1000);
            if (timeLeft <= 0) {
                clearInterval(countdownInterval);
                setCountdown(null);
                setIsGameOver(true);
    
                console.log('Setting showConfetti to true');
                setShowConfetti(true);
            } else {
                setCountdown(timeLeft);
            }
        }, 1000);
    };
    
    
    
  
    const startCountdown = () => {
        const countdownStart = countdown || 10; 
        resumeCountdown(countdownStart); 
    };


    if (!username) {
        return <Register onRegister={setUsername} />;
    }

    const lightbulbColors = ['#FF5733', '#33FF57', '#3357FF', '#FFFF33', '#FF33A1'];

    const toggleStats = () => {
        setShowStats(prevShowStats => !prevShowStats);
    };

    const leftRightBulbsCount = showStats ? 16 : 8;

    return (
        <>
            {countdown !== null && <div className="countdown">{countdown}</div>}
    
            {/* Rādīt konfeti, ja nepieciešams */}
            {showConfetti && (
                <div className="confetti-container">
                    <img src={confettiGif} alt="Confetti" className="confetti" />
                    <div className="success-message">
                        <span className="left">Mums</span>
                        <span>&nbsp;&nbsp;</span>
                        <span className="right">Izdevās!</span>
                    </div>
                </div>
            )}
    
            {/* Pārējie elementi, kas tiek rādīti vienmēr */}
            <Stats
                toggleStats={toggleStats}
                showStats={showStats}
                username={username}
                userClicks={userClicks}
                allTimeClicks={allTimeClicks}
                lightbulbColors={lightbulbColors}
                leftRightBulbsCount={leftRightBulbsCount}
                clickLimitOverride={clickLimitOverride}
            />
            <Button
                clickLimitReached={clickLimitReached}
                handleClick={handleClick}
                clickLimitOverride={clickLimitOverride}
                isGameButtonDisabled={isGameButtonDisabled}
                pauseMessage={pauseMessage}
                isGameOver={isGameOver}
            />
            <ImageDisplay unlockedDays={unlockedDays} isMuted={isMuted} totalClicks={totalClicks} />
        </>
    );
    
};

export default Poga;
