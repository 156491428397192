import React from 'react';
import LightFrame from './LightFrame';

const Stats = ({
    toggleStats,
    showStats,
    username,
    userClicks,
    allTimeClicks,
    lightbulbColors,
    leftRightBulbsCount,
    clickLimitOverride 
}) => {
    return (
        <div className="statsContainer" onClick={toggleStats}>
            <LightFrame position="top" length={20} colors={lightbulbColors} />
            <LightFrame position="bottom" length={20} colors={lightbulbColors} />
            <LightFrame position="left" length={leftRightBulbsCount} colors={lightbulbColors} />
            <LightFrame position="right" length={leftRightBulbsCount} colors={lightbulbColors} />

            <p className="logoText">Lampiņu Drudzis</p>
            <hr className="divider divider-title" />
    
            <p className="openStatsText">{showStats ? 'Aizvērt' : 'Atvērt'}</p>
            
           
            <hr className="divider divider-title" />

            {showStats && (
                <div className="statsInfo">
                    <h3>{username}</h3>
                    
                    <hr className="divider divider-username" />
                    <p>Tavi šodienas klikšķi: {clickLimitOverride ? 'Bezgalīgi' : userClicks}</p>
                    <p>Tavi visi klikšķi: {allTimeClicks}</p>
                
                </div>
            )}
        </div>
    );
};

export default Stats;
