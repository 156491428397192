import React from 'react';
import '../styles/lightbulb.css';

const LightFrame = ({ position, length, colors }) => {
    return (
        <div className={`lightFrame ${position}`}>
            {Array.from({ length }).map((_, index) => (
                <div
                    key={`${position}-${index}`}
                    className="lightbulb"
                    style={{ backgroundColor: colors[index % colors.length] }}
                />
            ))}
        </div>
    );
};

export default LightFrame;
