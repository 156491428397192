import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Music from './components/Music';
import Poga from './components/Poga';
import koncertzale from './images/koncertzal4.png';
import AdminPanel from './components/AdminPanel';

function App() {
   
    const [isMuted, setIsMuted] = useState(() => {
        const savedMuteState = localStorage.getItem('isMuted');
        return savedMuteState ? JSON.parse(savedMuteState) : false;
    });

    const toggleMute = () => {
        setIsMuted(prevMuteState => {
            const newMuteState = !prevMuteState;
            localStorage.setItem('isMuted', JSON.stringify(newMuteState));
            return newMuteState;
        });
    };

    return (
        <Router>
            <Routes>
                <Route path="/a9unlampinas" element={<AdminPanel />} />
                <Route
                    path="/"
                    element={
                        <div className="app-background">
                            <img src={koncertzale} alt="koncertZale" className="center-image" />
                            
                            <Music isMuted={isMuted} toggleMute={toggleMute} />
                            <Poga isMuted={isMuted} />
                        </div>
                    }
                />
            </Routes>
        </Router>
    );
}

export default App;
