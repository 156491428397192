import React from 'react';
import '../styles/button.css';


const Button = ({ clickLimitReached, handleClick, clickLimitOverride, isGameButtonDisabled, pauseMessage, isGameOver }) => {
    const isPauseActive = !!pauseMessage;
    

    return (
        <div className="pogaContainer">
            <div
  className={`pogaButton ${isGameOver ? 'game-over' : ''} ${isPauseActive ? 'pause-active' : ''} ${
    (clickLimitReached && !clickLimitOverride) || isGameButtonDisabled ? 'disabledButton' : ''
  }`}
  onClick={(clickLimitReached && !clickLimitOverride) || isGameButtonDisabled ? null : handleClick}
  style={{
    cursor: (clickLimitReached && !clickLimitOverride) || isGameButtonDisabled ? 'not-allowed' : 'pointer',
  }}
>
                <span className="pogaButton-text">
                    {isGameOver
                        ? 'Spēle noslēgusies!' 
                        : pauseMessage
                        ? pauseMessage
                        : (clickLimitReached && !clickLimitOverride) || isGameButtonDisabled
                        ? 'Limits Sasniegts, Nāc Rīt!'
                        : 'Spied Šeit'}
                </span>
            </div>
        </div>
    );
};

export default Button;
