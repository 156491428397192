import React, { useEffect } from 'react';

const Snow = () => {
  useEffect(() => {
    createSnow();
    return () => {
      removeParticles('snow-particle');
    };
  }, []);

  const createSnow = () => {
    const numParticles = 50; 
    const container = document.body;

    for (let i = 0; i < numParticles; i++) {
      const particle = document.createElement('div');
      particle.className = 'snow-particle';


      const size = randomInRange(5, 20);
      particle.style.width = `${size}px`;
      particle.style.height = `${size}px`;
      particle.style.opacity = `${randomInRange(0.7, 1.2)}`;
      particle.style.left = `${randomInRange(0, window.innerWidth)}px`;
      particle.style.top = `${randomInRange(-200, -50)}px`;

     
      particle.style.animationDuration = `${randomInRange(10, 30)}s`;
      particle.style.animationDelay = `${randomInRange(0, 10)}s`;

      container.appendChild(particle);
    }
  };

  const removeParticles = (className) => {
    const particles = document.getElementsByClassName(className);
    while (particles.length > 0) {
      particles[0].parentNode.removeChild(particles[0]);
    }
  };

  const randomInRange = (min, max) => Math.random() * (max - min) + min;

  return null;
};

export default Snow;
