import React, { useEffect, useRef, useState } from 'react';
import backgroundMusicMp3 from '../audio/game.mp3';
import backgroundMusicOGG from '../audio/game.ogg';

const Music = ({ isMuted, toggleMute }) => {
    const audioRef = useRef(null);
    const [isAudioPlayed, setIsAudioPlayed] = useState(false);

    useEffect(() => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const playAudio = () => {
            if (audioContext.state === 'suspended') {
                audioContext.resume().then(() => {
                    console.log('AudioContext resumed');
                    if (audioRef.current) {
                        audioRef.current.muted = true;
                        audioRef.current.play().then(() => {
                            console.log('Audio started muted');
                            audioRef.current.muted = isMuted; 
                            setIsAudioPlayed(true);
                        }).catch(error => console.log('Error playing audio:', error));
                    }
                });
            } else {
                if (audioRef.current) {
                    audioRef.current.play().catch(error => console.log('Error playing audio:', error));
                }
            }
        };
    
        const handleUserInteraction = () => {
            if (!isAudioPlayed) {
                playAudio();
            }
        };
    
        document.addEventListener('pointerdown', handleUserInteraction);
    
        return () => {
            document.removeEventListener('pointerdown', handleUserInteraction);
        };
    }, [isAudioPlayed, isMuted]);

    return (
        <div>
            <audio ref={audioRef} loop muted={isMuted}>
                <source src={backgroundMusicMp3} type="audio/mpeg" />
                <source src={backgroundMusicOGG} type="audio/ogg" />
                Pārlūks neatbalsta šo mūzikas formātu.
            </audio>
            <button className="mute-button" onClick={toggleMute}>
                {isMuted ? '🔇' : '🔊'}
            </button>
        </div>
    );
};

export default Music;
