
import React, { useState, useEffect } from 'react';
import LightFrame from './LightFrame';
import API from '../api';
import '../styles/register.css';

const Register = ({ onRegister }) => {
    const [username, setUsername] = useState('');
    const [ipAddress, setIpAddress] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then((response) => response.json())
            .then((data) => setIpAddress(data.ip))
            .catch((err) => console.error('Kļūda nosakot ip addresi:', err));
    }, []);

    const handleRegister = () => {
       
        if (!username.trim()) {
            setError('Lauks "Lietotājvārds" nedrīkst būt tukšs.');
            return;
        }
    
      
        if (!username.match(/^[a-zA-Z0-9]+$/)) {
            setError('Lietotājvārds drīkst saturēt tikai burtus un ciparus.');
            return;
        }
    
        
        API.post('/register', { username, ipAddress })
            .then((response) => {
                if (response.data.error) {
                    setError(response.data.error);
                } else {
                    localStorage.setItem('username', username);
                    localStorage.setItem('ipAddress', ipAddress);
                    onRegister(username);
    
                    if (response.data.message) {
                        alert(response.data.message);
                    }
                }
            })
            .catch((error) => {
                setError('Kļūda, lūdzu mēģini vēlreiz!');
                console.error('Kļūda reģistrējot lietotāju:', error);
            });
    };

    const lightbulbColors = ['#FF5733', '#33FF57', '#3357FF', '#FFFF33', '#FF33A1'];

    return (
        <div className="registerContainer">
            <LightFrame position="top" length={20} colors={lightbulbColors} />
            <LightFrame position="bottom" length={20} colors={lightbulbColors} />
            <LightFrame position="left" length={13} colors={lightbulbColors} />
            <LightFrame position="right" length={13} colors={lightbulbColors} />

            <div className="logoContainer">
                <LightFrame position="top" length={15} colors={lightbulbColors} />
                <LightFrame position="bottom" length={15} colors={lightbulbColors} />
                <LightFrame position="left" length={6} colors={lightbulbColors} />
                <LightFrame position="right" length={6} colors={lightbulbColors} />
                <p className="logoText">Lampiņu Drudzis</p>
            </div>

            <h2>Reģistrēties</h2>
            {error && <p className="errorMessage">{error}</p>}
            <input
                type="text"
                placeholder="Ievadi lietotājvārdu"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <button onClick={handleRegister}>Apstiprināt</button>
        </div>
    );
};

export default Register;
