import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../api';
import LightFrame from './LightFrame';
import '../styles/admin.css';

const AdminPanel = () => {
    const [adminUsername, setAdminUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [clickLimitOverride, setClickLimitOverride] = useState(false);
    const [countdown, setCountdown] = useState(null); 
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        const storedAdminUsername = localStorage.getItem('adminUsername'); 

        if (token && storedAdminUsername) {
            setIsAuthenticated(true);
            setAdminUsername(storedAdminUsername);
            fetchClickLimitOverrideStatus();
            fetchCountdown(); 
        }
    }, []);

    

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await API.post('/login', { username: adminUsername, password });
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('adminUsername', adminUsername); 
            setIsAuthenticated(true);
            fetchClickLimitOverrideStatus();
        } catch (err) {
            setError('Invalid username or password');
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('adminUsername'); 
        setIsAuthenticated(false);
        navigate('/');
    };

    const fetchClickLimitOverrideStatus = async () => {
        try {
            const response = await API.get('/admin/get-click-limit-override');
            setClickLimitOverride(response.data.clickLimitOverride);
        } catch (error) {
            console.error('Kļūda iegūstot statusu:', error);
        }
    };

    const toggleClickLimitOverride = async () => {
        const newStatus = !clickLimitOverride;
        setClickLimitOverride(newStatus);

        try {
            await API.post('/admin/set-click-limit-override', { clickLimitOverride: newStatus });
        } catch (error) {
            console.error('Kļūda mainot limitu:', error);
        }
    };

    const startCountdown = async () => {
        const duration = 10;
        try {
            await API.post('/admin/start-countdown', { duration });
            fetchCountdown();
        } catch (error) {
            console.error('Kļūda sākot countdown:', error);
        }
    };
    
    const fetchCountdown = async () => {
        try {
            const response = await API.get('/countdown');
            const { active, timeLeft, isGameOver: serverGameOver } = response.data;
    
            setCountdown(active ? timeLeft : null);
            if (serverGameOver) {
                console.log('Spēle ir beigusies.');
            }
        } catch (error) {
            console.error('Kļūda iegūstot countdown statusu:', error);
        }
    };
    const lightbulbColors = ['#FF5733', '#33FF57', '#3357FF', '#FFFF33', '#FF33A1'];

    return (
        <div className="adminContainer">
            <LightFrame position="top" length={27} colors={lightbulbColors} />
            <LightFrame position="bottom" length={27} colors={lightbulbColors} />
            <LightFrame position="left" length={20} colors={lightbulbColors} />
            <LightFrame position="right" length={20} colors={lightbulbColors} />

            {!isAuthenticated ? (
                <div>
                    <h2>Admin Login</h2>
                    {error && <p className="errorMessage">{error}</p>}
                    <form onSubmit={handleLogin}>
                        <input
                            type="text"
                            placeholder="Username"
                            value={adminUsername}
                            onChange={(e) => setAdminUsername(e.target.value)}
                            required
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <button type="submit">Login</button>
                    </form>
                </div>
            ) : (
                <div>
                    <h1>Admin Panelis</h1>
                    <p className="adminUsername">
                     <strong>{adminUsername}</strong>
                    </p>
                    <button onClick={handleLogout} className="logoutButton">Izrakstīties</button>
                    <div className="adminOptions">
                        <h3>Spēles iestatījumi</h3>
                        <div className="label">
                            <input
                                type="checkbox"
                                checked={clickLimitOverride}
                                onChange={toggleClickLimitOverride}
                            />
                            Ieslēgt bezgalīgos klikšķus
                        </div>
                        <button onClick={startCountdown} className="countdownButton">
                            Uzsākt Atskaiti
                        </button>
                    </div>
                    {countdown !== null && (
                        <div className="countdownDisplay">
                            <strong>Atlikušais laiks:</strong> {countdown} sekundes
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default AdminPanel;
