import React, { useEffect, useRef, useState } from 'react';
import '../styles/animations.css';
import '../styles/snow.css';
import '../styles/star.css';
import '../styles/stats.css';
import '../styles/base.css';
import '../styles/egleMeness.css';
import '../styles/catBante.css';
import '../styles/rotajumi.css';
import '../styles/sniegsEgle.css';
import '../styles/lampas.css';
import '../styles/snowmanGift.css';
import '../styles/santaFeet.css';
import '../styles/kupenas.css';
import '../styles/like.css';

import unlockSound from '../audio/unlock.mp3';

import egliiteImage from '../images/egle2.png'; 
import meness from '../images/meness.png';
import catGif from '../images/catnew.png';
import bante from '../images/bantiite.png';
import snowMan from '../images/snowman3.png';
import gift from '../images/gifts.png';
import sniegsEgle from '../images/sniegsegle.png';
import kepas from '../images/kepas.png';
import santa from '../images/santa.png';
import cathat from '../images/cathat.png';
import lampas from '../images/lampinas.png';
import lampas2 from '../images/lampas2.png';
import kupena from '../images/kupena.png';
import kupena2 from '../images/kupena2.png';
import rotajumi from '../images/rotajumi.png';
import stars from '../images/stars.png';
import likeGif from '../images/like.gif';

import Snow from './snow';

const ImageDisplay = ({ unlockedDays, isMuted, totalClicks }) => {
    const audioRef = useRef(new Audio(unlockSound));
    const [playedDays, setPlayedDays] = useState(() => {
        const savedDays = localStorage.getItem('playedDays');
        return savedDays ? JSON.parse(savedDays) : [];
    });
    const [lastUnlockedDays, setLastUnlockedDays] = useState(unlockedDays);
    const [showLikeGif, setShowLikeGif] = useState(false); 
    const [appearingElement, setAppearingElement] = useState(null); 

    useEffect(() => {
        if (totalClicks >= 361) {
            return; 
        }

        const newDaysToPlay = unlockedDays.filter(day => !playedDays.includes(day));
        const isNewUnlock = newDaysToPlay.length > 0 && unlockedDays !== lastUnlockedDays;

        if (isNewUnlock) {
           
            audioRef.current.volume = 0.3;
            audioRef.current.muted = isMuted;
            audioRef.current.play().catch(error => {
                console.error('Error playing unlock sound:', error);
            });

            
            setShowLikeGif(true);
            setTimeout(() => setShowLikeGif(false), 8000); 

           
            const latestUnlockedDay = Math.max(...newDaysToPlay);
            setAppearingElement(latestUnlockedDay);

           
            setTimeout(() => setAppearingElement(null), 5000);

          
            const updatedPlayedDays = [...playedDays, ...newDaysToPlay];
            setPlayedDays(updatedPlayedDays);
            localStorage.setItem('playedDays', JSON.stringify(updatedPlayedDays));
            setLastUnlockedDays(unlockedDays);
        }
    }, [unlockedDays, playedDays, isMuted, lastUnlockedDays, totalClicks]);

    return (
        <div className="imageDisplay">
            {unlockedDays.includes(1) && (
                <img
                    src={egliiteImage}
                    alt="Egliite"
                    className={`egle ${appearingElement === 1 ? 'appearing' : ''}`}
                />
            )}
            {unlockedDays.includes(1) && (
                <img
                    src={meness}
                    alt="Meness"
                    className={`meness ${appearingElement === 1 ? 'appearing' : ''}`}
                />
            )}

            {unlockedDays.includes(2) && (
                <img
                    src={rotajumi}
                    alt="Rotajumi"
                    className={`rotajumi ${appearingElement === 2 ? 'appearing' : ''}`}
                />
            )}

            {unlockedDays.includes(2) && (
                <img
                    src={rotajumi}
                    alt="Rotajumi"
                    className={`rotajumi2 ${appearingElement === 2 ? 'appearing' : ''}`}
                />
            )}

            {unlockedDays.includes(2) && (
                <img
                    src={rotajumi}
                    alt="Rotajumi"
                    className={`rotajumi3 ${appearingElement === 2 ? 'appearing' : ''}`}
                />
            )}



            {unlockedDays.includes(2) && (
                <Snow className={`${appearingElement === 2 ? 'appearing' : ''}`} />
            )}



            {unlockedDays.includes(3) && (
                <img
                    src={bante}
                    alt="Bante"
                    className={`bante ${appearingElement === 3 ? 'appearing' : ''}`}
                />
            )}

            {unlockedDays.includes(3) && (
                <img
                    src={sniegsEgle}
                    alt="SniegsEgle"
                    className={`sniegsegle ${appearingElement === 3 ? 'appearing' : ''}`}
                />
            )}  
            {unlockedDays.includes(3) && (
                <img
                    src={sniegsEgle}
                    alt="SniegsEgle"
                    className={`sniegsegle2 ${appearingElement === 3 ? 'appearing' : ''}`}
                />
            )}  


            {unlockedDays.includes(3) && (
                <img
                    src={sniegsEgle}
                    alt="SniegsEgle"
                    className={`sniegsegle3 ${appearingElement === 3 ? 'appearing' : ''}`}
                />
            )}

            {unlockedDays.includes(4) && (
                <img
                    src={sniegsEgle}
                    alt="SniegsEgle"
                    className={`sniegsegle4 ${appearingElement === 4 ? 'appearing' : ''}`}
                />
            )} 
            {unlockedDays.includes(4) && (
                <img
                    src={sniegsEgle}
                    alt="SniegsEgle"
                    className={`sniegsegle5 ${appearingElement === 4 ? 'appearing' : ''}`}
                />
            )} 
            {unlockedDays.includes(4) && (
                <img
                    src={sniegsEgle}
                    alt="SniegsEgle"
                    className={`sniegsegle6 ${appearingElement === 4 ? 'appearing' : ''}`}
                />
            )}

            {unlockedDays.includes(4) && (
                <img
                    src={kepas}
                    alt="Kepas"
                    className={`kepas ${appearingElement === 4 ? 'appearing' : ''}`}
                />
            )}



            {unlockedDays.includes(5) && (
                <img
                    src={catGif}
                    alt="Cat"
                    className={`cat ${appearingElement === 5 ? 'appearing' : ''}`}
                />
            )} 
            {unlockedDays.includes(5) && (
                <img
                    src={sniegsEgle}
                    alt="SniegsEgle"
                    className={`sniegsegle7 ${appearingElement === 5 ? 'appearing' : ''}`}
                />
            )}
            {unlockedDays.includes(5) && (
                <img
                    src={sniegsEgle}
                    alt="SniegsEgle"
                    className={`sniegsegle8 ${appearingElement === 5 ? 'appearing' : ''}`}
                />
            )}
            {unlockedDays.includes(5) && (
                <img
                    src={sniegsEgle}
                    alt="SniegsEgle"
                    className={`sniegsegle9 ${appearingElement === 5 ? 'appearing' : ''}`}
                />
            )}



            {unlockedDays.includes(6) && (
                <img
                    src={snowMan}
                    alt="Snowman"
                    className={`snowMan ${appearingElement === 6 ? 'appearing' : ''}`}
                />
            )}
             {unlockedDays.includes(6) && (
                <img
                    src={sniegsEgle}
                    alt="SniegsEgle"
                    className={`sniegsegle10 ${appearingElement === 6 ? 'appearing' : ''}`}
                />
            )}  
            {unlockedDays.includes(6) && (
                <img
                    src={sniegsEgle}
                    alt="SniegsEgle"
                    className={`sniegsegle11 ${appearingElement === 6 ? 'appearing' : ''}`}
                />
            )}

            {unlockedDays.includes(7) && (
                <img
                    src={sniegsEgle}
                    alt="SniegsEgle"
                    className={`sniegsegle12 ${appearingElement === 7 ? 'appearing' : ''}`}
                />
            )}
            {unlockedDays.includes(7) && (
                <img
                    src={sniegsEgle}
                    alt="SniegsEgle"
                    className={`sniegsegle13 ${appearingElement === 7 ? 'appearing' : ''}`}
                />
            )}
            {unlockedDays.includes(7) && (
                <img
                    src={sniegsEgle}
                    alt="SniegsEgle"
                    className={`sniegsegle14 ${appearingElement === 7 ? 'appearing' : ''}`}
                />
            )}

            {unlockedDays.includes(7) && (
                <img
                    src={lampas}
                    alt="Lampinas"
                    className={`lampas ${appearingElement === 7 ? 'appearing' : ''}`}
                />
            )}
            {unlockedDays.includes(7) && (
                <img
                    src={lampas}
                    alt="Lampinas"
                    className={`lampas2 ${appearingElement === 7 ? 'appearing' : ''}`}
                />
            )}
            {unlockedDays.includes(7) && (
                <img
                    src={lampas}
                    alt="Lampinas"
                    className={`lampas3 ${appearingElement === 7 ? 'appearing' : ''}`}
                />
            )} 
            {unlockedDays.includes(7) && (
                <img
                    src={lampas2}
                    alt="Lampinas2"
                    className={`lampas21 ${appearingElement === 7 ? 'appearing' : ''}`}
                />
            )}
             {unlockedDays.includes(7) && (
                <img
                    src={lampas2}
                    alt="Lampinas2"
                    className={`lampas22 ${appearingElement === 7 ? 'appearing' : ''}`}
                />
            )}



            {unlockedDays.includes(8) && (
                <img
                    src={cathat}
                    alt="CatHat"
                    className={`cathat ${appearingElement === 8 ? 'appearing' : ''}`}
                />
            )}

            {unlockedDays.includes(8) && (
                <img
                    src={gift}
                    alt="Gift"
                    className={`gift ${appearingElement === 8 ? 'appearing' : ''}`}
                />
            )}



            {unlockedDays.includes(9) && (
                <img
                    src={kupena}
                    alt="Kupena"
                    className={`kupena ${appearingElement === 9 ? 'appearing' : ''}`}
                />
            )}

            {unlockedDays.includes(9) && (
                <img
                    src={kupena2}
                    alt="Kupena2"
                    className={`kupenaV2 ${appearingElement === 9 ? 'appearing' : ''}`}
                />
            )}


            {unlockedDays.includes(9) && (
                <img
                    src={santa}
                    alt="Santa"
                    className={`santa ${appearingElement === 9 ? 'appearing' : ''}`}
                />
            )}

                {unlockedDays.includes(10) && (
                <img
                    src={kupena2}
                    alt="Kupena2"
                    className={`kupena2 ${appearingElement === 10 ? 'appearing' : ''}`}
                />
            )}



            {unlockedDays.includes(10) && (
                <img
                    src={stars}
                    alt="Stars"
                    className={`stars ${appearingElement === 10 ? 'appearing' : ''}`}
                />
            )}
            
            {unlockedDays.includes(10) && (
                <img
                    src={stars}
                    alt="Stars"
                    className={`stars2 ${appearingElement === 10 ? 'appearing' : ''}`}
                />
            )}
            
            {unlockedDays.includes(10) && (
                <img
                    src={stars}
                    alt="Stars"
                    className={`stars3 ${appearingElement === 10 ? 'appearing' : ''}`}
                />
            )}
            
            {unlockedDays.includes(10) && (
                <img
                    src={stars}
                    alt="Stars"
                    className={`stars4 ${appearingElement === 10 ? 'appearing' : ''}`}
                />
            )}
            
            {unlockedDays.includes(10) && (
                <img
                    src={stars}
                    alt="Stars"
                    className={`stars5 ${appearingElement === 10 ? 'appearing' : ''}`}
                />
            )}
            {unlockedDays.includes(10) && (
                <img
                    src={stars}
                    alt="Stars"
                    className={`stars6 ${appearingElement === 10 ? 'appearing' : ''}`}
                />
            )}

            {showLikeGif && totalClicks <= 301 && (
                <img src={likeGif} alt="Like" className="likeGif" />
            )}
        </div>
    );
};

export default ImageDisplay;
